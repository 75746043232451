html, body, #root {
  height: 100%;
}
ins {
  text-decoration-line: none;
}

[data-slate-editor] .ant-typography {
  margin-bottom: 4px;
}

.border-1 {
  border-width: 1px;
}

.display-none {
  display: none !important;
}

/* move upload button to the end of file list */
.upload-list-custom {
  display: flex;
  flex-direction: column-reverse;
  gap: 16px;
}


/* for plate */
.bg-background {
  background-color: #fff;
}

.bg-popover {
  background-color: #fff;
}

.bg-border {
  background-color: #ccc;
}

.text-primary {
  color: #1677ff;
}

.before\:bg-muted:before {
  background-color: rgba(0,0,0,0.03);
}

.timetable_late {
  width: 0.75rem;
  height: 0.75rem;
  background-color: #ffc000;
  border-radius: 50%;
  line-height: 1;
}

.debug {
  border: 1px solid red;
}

.debug10 {
  border: 10px solid red;
}
